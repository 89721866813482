import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {Outlet, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';
import vocab from '../../../config/channels';
import { operatorName } from '../../../config/operator';
import { TChannel } from '../../../contexts/operator/operator-context';
import useOperatorContext from '../../../contexts/operator/useOperatorContext';
import { TSite } from '../../../contexts/site/site-context';
import useSiteContext from '../../../contexts/site/useSiteContext';
import PageSpinnerThree from '../../general/PageSpinnerThree';
import AppLayout from '../../layouts/app/AppLayout';
import SiteFilter from './components/SiteFilter';
import useBasketContext from "../../../contexts/basket/useBasketContext";
import useScrollPosition from "../../../hooks/useScrollPosition";
import useOrderContext from "../../../contexts/order/useOrderContext";
import mixpanel from "mixpanel-browser";


type SiteSelectProps = {
    channel: TChannel;
};

const SiteSelect = ({ channel }: SiteSelectProps) => {
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();
    const basketContext = useBasketContext();
    const orderContext = useOrderContext();

    const scrollPosition = useScrollPosition();

    const navigate = useNavigate();

    useEffect(() => {

        basketContext.clearBasket();

        if (siteContext) {

            let flow = siteContext.flow === '' ? '' : (operatorContext.operator?.flow ?? 'noflow') ;
            console.warn('Site call from SiteSelect', flow);
            siteContext.fetchSites(operatorName, flow);
        } else {
            console.warn('Skipped fetch site call.');
        }
    }, []);

    useEffect(() => {
        // Something has gone wrong with loading operator, 404

        if ( siteContext && !siteContext.loading && !siteContext.sites ) {
            
            console.log('something went wrong - go to homepage');
            navigate('/');
        }

        if ( siteContext.sites?.length === 1 ) {

            let singleSite = siteContext.sites[0];
            let enabledChannels = Object.keys(singleSite.channels);

            if ( enabledChannels.length === 1 && enabledChannels[0] === 'dine_in' ) {
                navigate(`/${vocab.dineIn.handle}/${singleSite.handle}/table`);
            } else if ( enabledChannels.length === 1 && ['delivery', 'catering'].includes(enabledChannels[0]) ) {
                // nothing on purpose!
            } else if ( enabledChannels.length === 1 ) {
                navigate(`/${enabledChannels[0]}/${singleSite.handle}`);
            }
        }

        if ( ['delivery', 'catering'].includes(channel) ) {
            let postcode = localStorage.getItem('postcode') ?? undefined; // todo check this doesn't have weird side-effects
            if ( postcode === undefined ) { navigate('/') }
        }

    }, [ siteContext, channel ]);

    if (!siteContext || !siteContext.sites) {
        return <PageSpinnerThree show={true} />;
    }

    const dineInSites = siteContext.sites?.filter((site) => site.channels.dine_in);
    const collectionSites = siteContext.sites?.filter((site) => site.channels.collection);
    const deliverySites = siteContext.sites?.filter((site) => site.channels.delivery);
    const cateringSites = siteContext.sites?.filter((site) => site.channels.catering);


    const renderChannel = () => {
        switch (channel) {
            case vocab.collection.name:
                return (
                    <>
                        <SiteFilter sites={collectionSites as TSite[]} channel={vocab.collection} />
                    </>
                );

            case vocab.dineIn.name:
                return (
                    <>
                        <SiteFilter sites={dineInSites as TSite[]} channel={vocab.dineIn} />
                    </>
                );

            case vocab.delivery.name:
                return (
                    <>
                        <SiteFilter sites={deliverySites as TSite[]} channel={vocab.delivery} />
                        {/*<DeliveryTab />*/}
                    </>
                );

            case vocab.catering.name:
                return (
                    <>
                        <SiteFilter sites={cateringSites as TSite[]} channel={vocab.catering} />
                    </>
                );

            default:
                return <p>{t`No channel selected.`}</p>;
        }
    };

    return (
        <AppLayout page="sites">
            <Helmet>
                <title>
                    {operatorContext.operator?.name ?? ''} {t`Online Ordering - Powered by orderswift`}
                </title>
                <link rel="icon" href={operatorContext.operator?.theme?.favicon_url} />
            </Helmet>

            <Title className={`${scrollPosition > 0 ? 'with-shadow' : ''}`}>
                <div className="sites-title-container">
                    <button onClick={() => navigate('/')}>
                        <i className="cursor-pointer feather feather-chevron-left feather-24" />
                    </button>
                    <h2>Select location</h2>
                </div>
            </Title>

            <ChannelContent className='channel-content'>
                <div className='channelWrapper'>{renderChannel()}</div>
            </ChannelContent>
            <Outlet />
        </AppLayout>
    );
};

const Title = styled.div`
    
    position: sticky;
    top: 0;
    background-color: var(--layouts_headers_secondary_bg);
    z-index: 50;
    
    &.with-shadow {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    
    .sites-title-container {
    
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 0px;
        height: 48px;
        padding: 0 40px;
        @media screen and (max-width: 767px) { padding: 0 20px; }
        
        
        h2 {
            flex-grow: 1;
            text-align: center;
            justify-content: center;
            text-transform: uppercase;
            margin: 0;
            
            /* this is to make sure the title is centered! */  
            position: absolute;
            left: 53px;
            right: 53px; //this value so that the button is clickable      
        }
        button {
            background-color: transparent;
            border: 0;
            margin-left: -8px;
            color: var(--brand);
            padding: 12px;
            width: 40px;
            height: 40px;
            color: var(--brand);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            z-index: 2;
            transition: background-color 0.15s ease-in-out;
            :hover {
                background-color: var(--brand-10);
            }
        }
    }
`;

const ChannelContent = styled.div`
    flex-grow: 1;
    
    padding: 20px 0;

    .channelWrapper {
        max-width: 560px;
        margin: 0px auto;
        
        @media screen and (max-width: 767px) { 
            padding: 0 20px;
            margin: 0;
            max-width: 100%;    
        }
    }

`;

export default SiteSelect;
