import { createContext } from 'react';
import { APIError } from '../../services/api';
import {array} from "yup";

export interface Operator {
    data: OperatorData;
    errors: APIError;
    event_id: number;
    flow: string;
}

export interface OperatorData {
    name: string;
    handle: string;
    custom_domain: string | null;
    allow_special_notes: boolean;
    allow_menu_item_images: boolean;
    allow_stripe_test: boolean;
    allow_mobile_pay: boolean;
    allergen_info: string;
    opt_in_message: string | null;
    is_opt_in_default: boolean;
    country: string;
    ccy: string;
    locale: string;
    has_active_promos: boolean;
    has_timed_categories: boolean;
    theme: Theme;
    channels: { collection: boolean; delivery: boolean; dine_in: boolean; catering: boolean };
    flow?: string;
    meta_tag?: string;
    google_analytics?: string;
    zillionaire?: ZillionaireData
}

export interface ZillionaireData {
    payment: string;
    confirmation: string;
    promo: string|null
}

export interface Theme {
    logo_url: string;
    hero_url?: string;
    background?: string;
    background_menu?: string;
    favicon_url: string;
    color_bg: string;
    color_primary: string;
    color_secondary: string;
    color_banner: string;
    color_badge: string;
    color_badge_icon: string;

    font: Font;
    bodyFont: Font;

    fonts?: Font[];

    h1: Heading;
    h2: Heading;
    h3: Heading;
    h4: Heading;

    card: Card;

    cta: CTA;
    cta2: CTA2;

    nav_bg: string;
    nav_border: string;

    css_mobile_header_bg: string;
    css_bg_image: string;
    css_shadows: string;
    css_custom: string | null;
    color_primary_shade: string;
    color_secondary_shade: string;
    color_success: string;
    color_success_shade: string;
    color_danger_shade: string;
    color_warning: string;
    color_warning_shade: string;
    color_info: string;
    color_info_shade: string;
    color_shade_0: string;
    color_shade_1: string;
    color_shade_2: string;
    color_shade_5: string;
    color_border: string;
    background_xs_url?: string;

    slogan: string;
    nav_variant: string;
    nav_count: boolean;

    menu_item_variant: string;

    v3: Theme2
}

export interface Font {
    name: string;
    size: string;
    ttf: string | null;
    woff: string | null;
    woff2?: string | null;
    weight?: string;
}

export interface Heading {
    font: string;
    size: string;
    line: string;
    color: string;
    weight: string;
}

export interface Card {
    border: string;
    radius: string;
    shadow: string;
}

export interface CTA {
    font: string;
    size: string;
    weight: string;
    bg: string;
    color: string;
    hover: string;
    disabled: string;
}

export interface CTA2 {
    bg: string;
    color: string;
    disabled_bg: string;
    disabled_color: string;
}

export interface Theme2 {
    logo_url: string;
    logo_height: string;
    favicon_url: string;

    fonts?: Font[];

    layouts: ThemeLayout;
    ui: ThemeUi;
    text: ThemeText;
    language: ThemeLanguage;
    ux: ThemeUx;
    tipping: ThemeTipping;

    extra_css: string;
    default_opt_in: boolean;
}

export interface ThemeTipping {
    collection: boolean;
    delivery: boolean;
    dine_in: boolean;
    catering: boolean;
}

export interface ThemeLayout {
    headers: ThemeLayoutHeaders;
    hero: string;
    body: ThemeLayoutBody;
}

export interface ThemeLayoutHeaders {
    primary_bg: string;
    secondary_bg: string;
    borders: ThemeHeaderBorders
}

export interface ThemeHeaderBorders {
    channels: boolean;
    sites: boolean;
    menu: boolean;
}

export interface ThemeLayoutBody {
    channel_select_bg: string;
    site_select_bg: string;
    menu_bg: string;
}

export interface ThemeUi {
    nav: ThemeUiNav;
    banner: ThemeUiElement;
    buttons: ThemeUiButtons;
    other: ThemeUiOther;
    tiles: ThemeUiTiles
}

export interface ThemeUiTiles {
    radius: string;
}

export interface ThemeUiNav {
    channels: string;
    menu: ThemeUiElementMenu;
}

export interface ThemeUiElement {
    bg: string;
    color: string;
    size?: string;
}
export interface ThemeUiElementMenu {
    bg: string;
    color: string;
    border: string;
}
export interface ThemeUiElementAlt {
    border: string;
    color: string;
}

export interface ThemeUiButtons {
    cta: ThemeUiElement;
    cta_disabled: ThemeUiElement;
    cta_alt: ThemeUiElementAlt;
    cta_basket: ThemeUiElement;
    change_location: ThemeUiElement;
}

export interface ThemeUiOther {
    link: string;
    badge: string;
}

export interface ThemeText {
    display: ThemeTextDisplay;
    ui: ThemeTextUi;
}

export interface ThemeTextDisplay {
    font: string;
    weight: string;
    spacing: string;
    case: string;
    h1: ThemeTextElement;
    h2: ThemeTextElement;
    h3: ThemeTextElement;
    h4: ThemeTextElement;
}

export interface ThemeTextElement {
    height: string;
    color: string;
}

export interface ThemeTextUi {
    font: string;
    weight: string;
    spacing: string;
    case: string;
    cta: string;
    select_channel_nav: string;
    menu_nav: string;
}

export interface ThemeLanguage {
    slogan: string;
    opt_in: string;
    catering: string;
}

export interface ThemeUx {
    menu_nav: string;
    menu_layout: string;
    menu_tiles: string;
    filters: boolean;
    nearest: boolean;
}

export interface Errors {}

export type TOperatorSite = {
    id: number;
    uid: string;
    operator_id: number;
    name: string;
    site_handle: string;
    address_1: string;
    is_coming_soon: number; // should be boolean
    delivery_is_open: boolean;
    delivery_area_type: string; // find out types
    delivery_area_radius: number;
    delivery_area_postcodes: string | null;
    delivery_partner: string | null; // find out
    delivery_no_fee_threshold: number;
    delivery_fee: number;
    delivery_min_order: number;
    delivery_max_order: number;
    collection_min_order: number;
    collection_max_order: number;
    collection_is_open: boolean;
    lat: number;
    lng: number;
    collection_banner: string | null;
    delivery_banner: string | null;
    see_collection: number; // should be boolean
    see_delivery: number; // should be boolean
    is_off_list: number; // should be boolean
    collection_instructions_closed: string | null;
    delivery_instructions_closed: string | null;
    collection_instructions: string | null;
    collection_instructions_show_at_payment: number; // should be boolean
    delivery_instructions: string | null;
    delivery_instructions_show_at_payment: number; // should be boolean
    see_dine_in: number; // should be boolean
    dine_in_is_open: boolean;
    dine_in_instructions: string | null;
    dine_in_instructions_closed: string | null;
    dine_in_banner: string | null;
    dine_in_instructions_show_at_payment: number; // should be boolean
    dine_in_instructions_show_at_confirmation: number; // should be boolean
    dine_in_service_fee_type: string; // find types
    dine_in_service_fee_amount: string; // why is this string
    hasCollectionSlots: boolean;
    hasDeliverySlots: boolean;
    hasDineInSlots: boolean;
    closures: any[];
};

export type TChannelInPerson = 'collection' | 'dine_in';

export type TChannel = 'delivery' | TChannelInPerson | 'catering';

export type OperatorContextData = {
    operator: OperatorData | null;
    setOperator: (operator: OperatorData | null) => void;

    fetchOperator: (handle: string) => void;

    loading: boolean;
    setLoading: (state: boolean) => void;
};

export const OperatorContext = createContext<OperatorContextData>({
    operator: null,
    setOperator: () => {},

    fetchOperator: () => {},

    loading: false,
    setLoading: () => {},
});
