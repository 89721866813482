import config from '../config/channels';

export const getDisplayName = (channel: string) => {
    switch (channel) {
        case 'collection':
            return config.collection.displayName;
        case 'delivery':
            return config.delivery.displayName;
        case 'catering':
            return config.delivery.displayName;
        case 'dine-in':
            return config.dineIn.displayName;
        case 'dine_in':
            return config.dineIn.displayName;
    }
};
