import { rgba } from 'polished';
import React, { useContext, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionCollapse,
    AccordionContext,
    Form,
    useAccordionToggle,
} from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import useOrderContext from '../../../../../contexts/order/useOrderContext';
import useOperatorContext from "../../../../../contexts/operator/useOperatorContext";
import { Item, TSite } from '../../../../../contexts/site/site-context';
import useSiteContext from '../../../../../contexts/site/useSiteContext';
import { getDisplayName } from '../../../../../services/channel';
import { money } from '../../../../../services/format';
import BasketItem from './BasketItem';
import TimeslotSelect from '../../../../timeslots/TimeslotSelect';
import TipAddBasket from './TipAddBasket';
import PromoForm from '../../../Checkout/components/PromoForm';
import InfoCard from './InfoCard';
import {iLog} from "../../../../../index";
import {ReadyTime, TBasket} from "../../../../../contexts/basket/basket-context";
import mastercard from '../../../../../assets/images/mastercard.svg';
import visa from '../../../../../assets/images/visa.svg';
import amex from '../../../../../assets/images/amex.svg';
import card_icon from '../../../../../assets/images/feather/credit-card.svg';
import ServiceCheckbox from "./ServiceCheckbox";
import config from '../../../../../config/channels';


type BasketProps = {
    showTimeslotModal?: (show: boolean) => void;
    checkout?: boolean;
    confirmation?: boolean;
    reset: boolean;
    site: TSite | null | undefined;
    // are the items we receive back the same
};

const Basket = ({ checkout = false, reset = false, site, showTimeslotModal, confirmation = false }: BasketProps) => {
    const basketContext = useBasketContext();
    const siteContext = useSiteContext();
    const operatorContext = useOperatorContext();
    const orderContext = useOrderContext();

    // promo code
    const [promoCode, setpromoCode] = useState('');
    const [promoLoading, setPromoLoading] = useState(false);

    const [bItems, setBItems] = useState<Item[]>([]);
    const [bItemsCount, setBItemsCount] = useState<string>('');
    const [bSite, setBSite] = useState<TSite | null | undefined>(site);

    const [validatedTimeslot, setValidatedTimeslot] = useState<ReadyTime|undefined>(undefined);
    const [showBItemModal, setShowBItemModal] = useState(false);
    const [modalBItem, setModalBItem] = useState<null | Item>(null);
    const [modalBItemIndex, setModalBItemIndex] = useState(-1);

    const [showService, setShowService] = useState(true);
    const [showTipping, setShowTipping] = useState(false);


    const isDineIn = siteContext.channel === config.dineIn.name;

    const showItemModal = (item: Item, index: number) => {
        setModalBItem(item);
        setShowBItemModal(true);
        setModalBItemIndex(index);
    };

    const selectServiceByDefault = (validatedBasket: TBasket|null|undefined) => {
        if ( !validatedBasket ) { return false; }
        if ( validatedBasket.service_fee === 0 ) { return false; }
        if ( !validatedBasket.service_optional ) { return true; }
        return validatedBasket.service_default ?? false;
    }


    useEffect(() => {
        setShowTipping(operatorContext.operator?.theme.v3.tipping[basketContext.validatedBasket?.channel ?? 'collection'] ?? false);
        setShowService(isDineIn && (basketContext.validatedBasket?.service_fee ?? 0) > 0);
        orderContext.setServiceSelected( selectServiceByDefault(basketContext.validatedBasket) );

        if ( confirmation ) {
            setBItems(orderContext.storedOrder?.basket ?? []);
        } else if (checkout) {
            setBItems(basketContext.validatedBasket?.basket ?? []);
            setValidatedTimeslot(basketContext.validatedBasket?.ready_time);
        } else {
            setBItems(basketContext.basketItems);
        }

        let count = 0;
        bItems.forEach((item: Item) => {
            count += item.quantity ?? 0;
        });

        setBItemsCount('('+ count + ' item' + (Number(count) > 1 ? 's)' : ')'));

    }, [basketContext.basketItems, basketContext.validatedBasket, orderContext.storedOrder]);

    useEffect(() => {
        if (checkout) {
            setBSite(basketContext.validatedSite ?? null);
        } else {
            setBSite(siteContext.site);
        }
    }, [checkout, siteContext.site, basketContext.validatedSite]);

    useEffect(() => {
        if (reset) {
            basketContext.clearBasket();
        }
    }, []);

    // useeffect timeslot, send validation if checkout

    function CustomToggle({ children, eventKey, callback }: any) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <button
                type="button"
                className={`your-order-toggle customToggle ${isCurrentEventKey ? 'show' : ''}`}
                onClick={decoratedOnClick}
            >
                {children}
                <i className="feather feather-chevron-down feather-24 brand-color-filter"/>
            </button>
        );
    }

    const subtotal = () => {
        if ( confirmation ) { return orderContext.storedOrder?.subtotal ?? 0; }
        if ( checkout ) { return basketContext.validatedBasket?.subtotal ?? 0; }
        return basketContext.price.subtotal;
    }

    const service = () => {
        if ( confirmation ) { return orderContext.storedOrder?.service ?? 0; }
        if ( checkout ) { return basketContext.validatedBasket?.service_fee ?? 0; }
        return 0;
    }

    const total = () => {
        if ( confirmation ) { return orderContext.storedOrder?.total ?? 0; }
        if ( checkout ) {

            let base = basketContext.validatedBasket?.total ?? 0;

            if ( isDineIn ) {
                base = orderContext.serviceSelected
                    ? basketContext.validatedBasket?.total_service_selected ?? 0
                    : basketContext.validatedBasket?.total_service_unselected ?? 0
            }

            return base + orderContext.gratuityVal;
        }
        return basketContext.price.total;
    }

    const siteName = () => {
        if ( confirmation ) { return orderContext.storedOrder?.site?.name; }
        return basketContext.validatedSite?.name;
    }

    const readyTime = () => {
        if ( confirmation ) { return orderContext.order?.ready_time; }
        return basketContext.validatedBasket?.ready_time;
    }

    const tableNo = () => {
        if ( confirmation ) { return orderContext.storedOrder?.table ?? ''; }
        return basketContext.tableNumber;
    }

    const deliveryFee = () => {
        if ( confirmation ) { return orderContext.storedOrder?.delivery.fee ?? 0; }
        return basketContext.validatedBasket?.delivery_fee ?? 0;
    }



    const renderBasket = () => {
        return (
            <BasketWrapper>
                <StyledAccordion defaultActiveKey="-1">
                    <CustomToggle eventKey="0">
                        <div className="toggle">
                            <span className="toggle__title">Your order</span>
                            <span>({bItems.reduce((total, item: Item) => total + (item.quantity ?? 0), 0)}{(bItems.reduce((total, item: Item) => total + (item.quantity ?? 0), 0) > 1 ? ' items' : ' item')})
                        </span>
                            {/*<span className="toggle__price only-show-mobile">*/}
                            {/*    {money(checkout ? orderContext.total ?? 0 : basketContext.price.total)}*/}
                            {/*</span>*/}
                        </div>
                    </CustomToggle>

                    <AccordionCollapse eventKey="0" timeout={2000}>
                        <>
                            {/*{renderChannelTitle(true)}*/}
                            <BasketItems>
                                {bItems.map((item, index) => (
                                    <BasketItem
                                        key={`basket-${index}-${item.uid}`}
                                        handleClick={checkout ? () => {} : () => showItemModal(item, index)}
                                        item={item}
                                        checkout={checkout || confirmation}
                                        last={index === bItems.length - 1}
                                    />
                                ))}
                            </BasketItems>
                        </>
                    </AccordionCollapse>
                </StyledAccordion>
            </BasketWrapper>
        );
    };

    const renderDeliveryFee = () => {
        if ( checkout && !confirmation && deliveryFee() === 0 ) {
            return null;
        }

        if ( confirmation && deliveryFee() === 0 ) {
            return null;
        }

        return (
            <div className="basket__subtotal delivery_fee">
                <span className="d-block w-100">{t`Delivery Fee`}</span>
                <span>{money(deliveryFee(), operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
            </div>
        );
    };

    const renderGratuity = () => {

        if ( confirmation && (orderContext.storedOrder?.service ?? 0) === 0 ) {
            return null;
        }
        // when not enable via the theme..
        if ( !showTipping ) {
            return null;
        }
        return (
            <TipAddBasket
                id={'tipBasketID'}
                tipValue={orderContext.gratuityVal}
                disabled={false}
                // disabled={!basketContext.validatedBasket?.service_optional}
                defaultChecked={false}
                selected={orderContext.gratuitySelected}
                isConfirmationPage={confirmation}
            />
        );
    }

    const renderSubtotal = () => {
        return (
            <Subtotal confirmation={confirmation}>
                <div className="basket__subtotal">
                    <span className="d-block w-100">{t`Subtotal`}</span>
                    <span>
                        {money(subtotal(), operatorContext.operator?.ccy, operatorContext.operator?.locale)}
                    </span>
                </div>

                {renderDeliveryFee()}
                {/*{renderPromoDiscountLine()}*/}
                {renderGiftCardLine()}

            </Subtotal>
        );
    };

    const renderPromoDiscountLine = () => {

        if ( confirmation ) {
            return orderContext.storedOrder?.codes
                .filter((code) => code.amount > 0 )
                .map((code) => {
                    return (
                        <DiscountLine key={code.text}>
                            <div className="basket__subtotal" style={{marginTop: '16px'}}>
                                <span className="d-block w-100">{code.text}</span>
                                <span className="non_wrapped">{money(-code.amount, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
                            </div>
                        </DiscountLine>
                    )
                });
        }

        if ( checkout ) {
            return basketContext.validatedBasket?.codes
                .filter((code) => code.amount > 0 )
                .map((code) => {
                    return (
                        <DiscountLine key={code.text}>
                            <div className="basket__subtotal" style={{marginTop: '16px'}}>
                                <span className="d-block w-100">{code.text}</span>
                                <span className="non_wrapped">{money(-code.amount, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
                            </div>
                        </DiscountLine>
                    )
                });
        }




    };

    const renderGiftCardLine = () => {
        if ( !confirmation ) { return null; }

        if ( !orderContext.storedOrder?.payments?.filter((pa) => pa.type === 'giftcard')[0] ) {
            return null;
        }

        let text = `Giftcard`;
        let discount = orderContext.storedOrder?.payments?.filter((pa) => pa.type === 'giftcard')[0].amount

        return (
            <DiscountLine>
                <div className="basket__subtotal" style={{ marginTop: '16px' }}>
                    <span className="d-block w-100">{text}</span>
                    <span className="non_wrapped">{money(-discount, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
                </div>
            </DiscountLine>
        );
    };

    const renderTotal = () => {
        return (
            <Total confirmation={confirmation}>
                <span>{t`Total`}</span>
                <span>{money(total(), operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
            </Total>
        );
    };

    const renderChannelTitle = (isMobile: boolean) => {
        //console.log('Validated site', basketContext.validatedSite, bSite);

        let fromLocation = 'from ' + orderContext.storedOrder?.site?.name;

        if (
            siteContext.site?.channels &&
            Object.keys(siteContext.site?.channels).length === 1 &&
            siteContext.site?.sites_count === 1
        ) {
            fromLocation = '';
        }

        if ( ['collection', 'delivery', 'catering'].includes(siteContext.channel) ) {
            let timeslot = readyTime()?.name;
            let timeslot_str = '';

            if (timeslot) {

                const parts = timeslot.split(" ");
                const time = parts.pop(); // Extract the time (last part of the string)
                const rest = parts.join(" "); // Join the remaining parts back into a string

                timeslot_str = `${time}, ${rest}`;

                if ( readyTime()?.is_asap ) {
                    timeslot_str = timeslot;
                }

            }

            iLog('Your timeslot is', [ timeslot, basketContext.validatedBasket?.ready_time ]);

            let displayName = getDisplayName(siteContext.channel);

            if ( basketContext.validatedBasket?.channel === 'catering' ) {
                displayName = operatorContext.operator?.theme.v3.language.catering;
            }

            return (
                <OrderInfo checkout={checkout} className={'basket__order-info basket__order-title'}>
                    <h2 className={"basket__order-info__title"} >{t`Summary`}</h2>
                    <div className={'info-card'}>
                        <span className="info-card__channel">{displayName}</span>
                        <span className="info-card__site_name">{operatorContext.operator?.name ?? ''}, {siteName()}</span>
                        <span className="info-card__timeslot" dangerouslySetInnerHTML={{ __html: timeslot_str }}></span>
                    </div>
                    {siteContext.site?.show_instructions_at_checkout && siteContext.site?.instructions ? (
                        <Instructions>
                            <i className="feather feather-info" />
                            <span>{ siteContext.site?.instructions }</span>
                        </Instructions>
                    ) : null}
                </OrderInfo>
            );

            // return ( <>For {getDisplayName(siteContext.channel)?.toLocaleLowerCase()} {timeslot} {fromLocation}</> );
        }

        // 20230327: REIMPLEMENT LATER..
        /*if (siteContext.channel === 'delivery') {
            let timeslot = basketContext.timeslot?.name;

            let formattedDate = '';

            if (basketContext.timeslot) {
                let dateParts = basketContext.timeslot.id.split(' ')[0].split('-');
                formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
            }

            if (timeslot) {
                // timeslot = timeslot.replace('Today', 'today at');
                // timeslot = timeslot.replace('Tomorrow', 'tomorrow at');
            }

            return (
                <OrderInfo className={'basket__order-info' + (isMobile ? "basket__order-info--mobile" : "")}>
                    <h5 className="mb-4">{t`Order summary`}</h5>
                    <div className="mb-2">
                        <i className="mr-3 feather feather-map-pin feather-18" />
                        <div>
                            <span> {getDisplayName(siteContext.channel)} </span>
                            {t`from`}
                            <span className="font-weight-bold"> {basketContext.validatedSite?.name}</span>
                        </div>
                    </div>
                    <div className="mb-2">
                        <i className="mr-3 feather feather-calendar feather-18" />
                        <div>
                            <span className="font-weight-bold"> {timeslot?.split(' ')[0]} </span>
                            {/!* To do: replace with the real date *!/}
                            <span> ({formattedDate}) </span>
                        </div>
                    </div>
                    <div className="mb-3 mb-sm-4">
                        <i className="mr-3 feather feather-clock feather-18" />
                        <span className="font-weight-bold"> {timeslot?.split(' ')[1]} </span>
                    </div>
                </OrderInfo>
            );

            // return ( <>For {getDisplayName(siteContext.channel)?.toLocaleLowerCase()} {timeslot} {fromLocation}</> );
        }
        */

        if (siteContext.channel === 'dine_in') {
            return (
                <OrderInfo checkout={checkout} className={'basket__order-info' + (isMobile ? " basket__order-info--mobile" : " basket__order-info--desktop")}>
                    <h2 className={"basket__order-info__title"} >{t`Summary`}</h2>
                    <div className={'info-card'}>
                        <InfoCard icon={'map-pin'} className="withBg mb-0">
                            <>
                                <span> Table {tableNo()} - {siteName()}</span>
                            </>
                        </InfoCard>
                    </div>
                    {siteContext.site?.show_instructions_at_checkout && siteContext.site?.instructions ? (
                        <Instructions>
                            <i className="feather feather-info" />
                            <span>{ siteContext.site?.instructions }</span>
                        </Instructions>
                    ) : null}
                </OrderInfo>
            );
        }


    };

    const getCardImage = (card:String) => {
        switch (card) {
            case 'visa':
                return visa;
            case 'mastercard':
                return mastercard;
            case 'amex':
                return amex;
            default:
                return card_icon
        }
    }

    const paymentDetails = () => {
        return {
            // last4: orderContext.storedOrder?.payments.last4 ?? '',
            // brand: orderContext.storedOrder?.payments.type ?? ''
            last4: orderContext.storedOrder?.payments.filter((pa) => pa.type !== 'giftcard')[0].last4 ?? '',
            brand: orderContext.storedOrder?.payments.filter((pa) => pa.type !== 'giftcard')[0].type ?? ''
        }
    }

    return (
        <BasketContainer checkout={checkout} confirmation={confirmation}>


            <BasketCard className="basket">
                {!checkout ? (
                    <StyledForm>
                        <Form.Group controlId="basketForm">
                            <TimeslotSelect
                                timeslots={siteContext.timeslots}
                                selectedTimeslotId={basketContext.timeslot?.id}
                                background
                            />
                        </Form.Group>
                    </StyledForm>
                ) : (
                    <div>
                        {renderChannelTitle(false)}
                        <OrderSummary>
                            {bItems.length ? (
                                <>
                                    {renderBasket()}
                                    {/*{renderChannelTitle(true)}*/}
                                    <div className='basket__order-lines'>
                                        {renderSubtotal()}
                                    </div>
                                    <div className="extrasContainer">

                                        {basketContext.specialNotes ? (
                                            <div className={'specialNotesContainer'}>
                                                <p>{t`Special instructions`}</p>
                                                <div className={'specialNotes'}>
                                                    {basketContext.specialNotes}
                                                </div>
                                            </div>
                                        ) : null}
                                        { !confirmation && (
                                            <div>
                                                <div className="promoContainer">
                                                    <PromoForm />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    { showService && (
                                        <div className='basket__order-lines'>
                                            <ServiceCheckbox
                                                id={'service'}
                                                tipValue={service()}
                                                disabled={(!basketContext.validatedBasket?.service_optional) ?? false}
                                                defaultChecked={orderContext.serviceSelected}
                                                selected={orderContext.serviceSelected}
                                                isConfirmationPage={checkout}
                                                />
                                        </div>
                                    )}
                                    { showTipping && checkout && (
                                        <div className='basket__order-lines'>
                                            {renderGratuity()}
                                        </div>
                                    )}
                                    <div className='basket__order-lines basket__order-lines--end'>
                                        {renderTotal()}
                                    </div>
                                    {confirmation && total() > 0 ? (
                                        <PaymentCardDetails className={'basket__payment-info'}>
                                            <div className={"basket__payment-info__text"}>
                                                Paid with
                                            </div>
                                            <div className={"basket__payment-info__card"}>
                                                <span className={''}>••••{paymentDetails().last4}</span>
                                                <img width={'32'} height={'24'} src={getCardImage(paymentDetails().brand)}/>
                                            </div>
                                        </PaymentCardDetails>

                                    ) : null}
                                </>
                            ) : (
                                <p className="text-center p-4 d-flex align-items-center justify-content-center flex-grow-1">{t`Your basket is empty`}</p>
                            )}

                        </OrderSummary>
                    </div>
                )}
            </BasketCard>
        </BasketContainer>
    );
};

const Instructions = styled.div`
    padding: 18px;
    margin-bottom: 24px;
    border-radius: 4px;
    
    background-color: var(--bg-grey);
    color: var(--text-black);
    i {
        margin-right: 12px;
    }
   
`;

const PaymentCardDetails = styled.div`
    
    &.basket__payment-info {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 20px;
        font-size: 16px;
        font-weight: 500;
        @media screen and (min-width: 993px) {
            border-top: 1px solid var(--border-grey);
            padding-bottom: 0;
        }
    }
    .basket__payment-info {
        &__text {
            flex-grow: 1;
        }
        &__card {
            display: flex;
            align-items: center;
            gap: 16px;
           
        }
    }
    
`

const StyledAccordion = styled(Accordion)`
    .customToggle {
        
        transition: background-color 0.15s ease-in-out;
        i { 
            transition: transform 0.15s ease-in-out;
        }
        &.show {
            i {
                transform: rotate(180deg);
            }
        }
        @media screen and (min-width: 993px) {
            // display: none;
        }
    }
    .promoSubmit {
        height: 48px;
        margin: 0;
        padding: 0 23px;
        margin-left: 16px;

        @media screen and (min-width: 768px) {
            padding: 0 40px;
            min-width: 152px;
            margin-left: 24px;
        }
    }
    .collapse, .collapsing {
        @media screen and (min-width: 993px) {
            border-bottom: 0;
        }
    }
    > button {
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        display: flex;
        padding: 20px;
        border-top: 1px solid var(--border-grey);
        &.show {
            border-bottom: 1px solid var(--border-grey);
        }
        @media screen and (min-width: 993px) {
            padding: 20px 0;
        }
        margin: 0;
        font-weight: 600;
        align-items: center;
        color: var(--text-black);
        text-align: left;

        img {
            transition: transform 0.2s linear;
        }

        &.show {
            img {
                transform: rotate(180deg);
            }
        }

        &.show {
            .toggle {
            }
        }

        .toggle {
            width: 100%;
            display: flex;
            /* margin-left: 16px; */
            color: ${(props) => props.theme.shade_0};
            flex-wrap: wrap;
            flex-grow: 1;
            > span {
                margin-right: 8px;
                color: var(--brand);
            }
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;

            &__price {
                flex-grow: 1;
                text-align: right;
            }
        }
    }
`;

const PromoDetails = styled.div`
    span {
        font-size: 14px;
        font-weight: 500;
    }
    img {
        margin-right: 8px;
        cursor: pointer;
    }
`;

const BasketItems = styled.div`
    font-size: 16px;
    padding: 12px 0;
`;

const OrderInfo = styled.div<{ checkout?: boolean }>`
    .info-card  {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__channel, &__site_name, &__timeslot {
            font-size: 16px;
            line-height: 22px;
        }
            
        &__site_name, &__timeslot {
            font-weight: 400;
            color: var(--text-grey)
        }
        &__channel {
            font-weight: 500;
        }   
        @media screen and (min-width: 993px){
            padding-bottom: 16px;
        }
    }

    img {
        margin-right: 9px;
    }


`;

const OrderSummary = styled.div`
    .promoSubmit {
        height: 48px;
        margin: 0;
        padding: 0 23px;
        margin-left: 16px;

        @media screen and (min-width: 768px) {
            padding: 0 40px;
            min-width: 152px;
            margin-left: 24px;
        }
    }
    .specialNotesContainer {
        p {
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
        }
        .specialNotes {
            border-radius: 4px;
            background-color: var(--bg-grey);
            padding: 12px 16px;           
            color: var(--text-grey);
        }
    }
    .specialNotesContainer {
        padding: 16px 0;
    }
   
`;

const Subtotal = styled.div<{ confirmation: boolean }>`
    font-size: var(--core_text_id_md);
    padding: 0;
    border-bottom: none !important;
    

    .basket__subtotal {
        /* padding: 8px 0; */
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
            font-weight: 400;
            font-size: var(--core_text_ui_lg);
            color: ${(props) => props.theme.color_shade_1}; // BLACK
            @media screen and (min-width: 576px) {
                font-size: var(--core_text_ui_lg_desktop);
            }
        }
    }
    
    .delivery_fee {
        margin-top: 16px;
    }
    
  
`;

const DiscountLine = styled.div`
    .non_wrapped {
        white-space: nowrap;
    }
`;

const Total = styled.div<{ confirmation: boolean }>`
    /* border-top: 1px solid ${(props) => props.theme.color_border}; */
    display: flex;
    font-size: var(--core_text_ui_lg);
    font-weight: 500;
    padding: 0;
    @media screen and (min-width: 576px) {
        font-size: var(--core_text_ui_lg_desktop);
    }
    @media screen and (min-width: 993px) {
        padding-bottom: 0;
    }
    span {
        color: ${(props) => props.theme.color_shade_1}; // BLACK
        font-weight: 500;
        &:first-child {
            display: block;
            width: 100%;
        }
    }
    
                
`;

const BasketWrapper = styled.div`
  
    .your-order-toggle {
      padding: 16px 0;
    }

    @media screen and (min-width: 768px) {
        /* max-height: calc(100vh - 330px); */
        overflow-y: visible;
        overflow-x: visible;
        scrollbar-width: none;

    }
`;

const BasketContainer = styled.div<{ checkout: boolean, confirmation: boolean }>`
    margin: 0;
    
    padding: 15px;
    flex-grow: 0;
    .only-show-mobile {
        @media screen and (min-width: 993px) {
            display: none;
        }
    }
    .only-show-desktop {
        display: none;
        @media screen and (min-width: 993px) {
            display: block;
        }
    }
    ${(props) =>
        props.checkout ? `
            margin-top: 0;
            order: 2;
            padding: 0;
            ${BasketCard}{
                width: 100%;
                box-shadow: none;
            }
        ` : `
            @media screen and (max-width: 767px){
                display: none;
            }
        `
    }
     ${(props) => props.confirmation ? `.customToggle {display: none} .collapse { display: block }` : ``}
`;
const BasketCard = styled.div`
    padding: 0 20px;
    width: 100%;
    z-index: 1;
    background: #ffffff;
    width: 350px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--border-grey);
    
    @media screen and (min-width: 993px) {
        border-bottom: 0;
        padding: 0;
    }

    @media screen and (min-width: 1140px) {
        top: 84px; //(64 + 20)
    }

    hr {
        width: 100vw;
        position: relative;
        left: -16px;
        margin: 16px 0;

        @media screen and (min-width: 768px) {
            left: -24px;
            max-width: 520px;
        }
    }
    
    .basket__order-lines {
        padding: 16px 0;
        line-height: 22px;
        border-top: 1px solid var(--border-grey);
        &--end {
            border-top: 0;
            padding-top: 0;
            padding-bottom: 32px;
        }
        @media screen and (min-width: 993px) {
           &--end {
                border-top: 0;
                padding-bottom: 8px;
            }
        }
    }
    
   

    .basket__order-info {
        padding: 32px 0px;
        
        
        @media screen and (min-width: 993px) {
            padding: 0;
        }

        &--desktop {
            // display: none;
            > div.info-card {

                /*&:not(:last-child) {
                    border-bottom: 1px solid var(--border-grey);
                }*/
            }
            @media screen and (min-width: 993px) {
                display: block;
            }
        }
        &--mobile {
            border-bottom: 1px solid var(--border-grey);
        }

        &__card {
            padding: 16px;
            border-radius: 8px;
            display: flex;
            gap: 16px;
            background-color: ${(props) => rgba(props.theme.v3.ui.other.link, 0.1)};
            span {
                font-size: 16px;
                line-height: 22px;
                /*font-family: 500; not sure what this value is.. */
            }
            @media screen and (min-width: 993px) {
                padding: 0;
                background-color: transparent;
            }
        }

        &__title {
            margin-bottom: 20px;
        }
    }
    
     .basket__order-title {
        padding-top: 32px;
        padding-bottom: 16px;
        
        @media screen and (min-width: 993px){
            padding-top: 0;
            padding-bottom: 0;
        }
    }
`;

const StyledForm = styled(Form)``;

export default Basket;