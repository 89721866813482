import React, {useEffect, useState} from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { t } from 'ttag';
import config from '../../../../config/channels';
import { operatorName } from '../../../../config/operator';
import { TSite } from '../../../../contexts/site/site-context';
import { validatePostcode } from '../../../../services/api/site';
import { CTA } from '../../../general/Button';
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";
import styled from 'styled-components';
import {iLog} from "../../../../index";
import {findLoquateData, retrieveLoquateData} from "../../../../services/api/loquate";
import {AxiosResponse} from "axios";
import {setIn} from "formik";
import PageSpinnerThree from "../../../general/PageSpinnerThree";
import PageSpinnerTwo from "../../../general/PageSpinnerTwo";
import {trackAlert} from "../../../../services/api/alert";
import {TChannel} from "../../../../contexts/operator/operator-context";

type DeliveryTabProps = {
    channel?: TChannel | null;
};
const DeliveryTab = ({ channel }: DeliveryTabProps) => {

    const operatorContext = useOperatorContext();

    const navigate = useNavigate();

    const [postcode, setPostcode] = useState('');
    const [loading, setLoading] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [inlineErrorMessage, setInlineErrorMessage] = useState('');
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [isValidPostcode, setIsValidPostcode] = useState(false);
    const [addressOptions, setAddressOptions] = useState([]);

    const handleDeliveryStart = (formattedPostcode: string) => {
        if (!formattedPostcode || formattedPostcode.length < 2) {
            return;
        }

        setLoading(true);

        validatePostcode(operatorName, formattedPostcode, operatorContext.operator?.flow ?? 'noflow', channel ?? 'delivery')
            .then((sites: TSite[]) => {
                iLog('validatePostcode', [formattedPostcode, sites]);
                localStorage.setItem('postcode', formattedPostcode.toUpperCase());
                if ( sites.length === 1 ) {
                    navigate(`/${channel}/${sites[0].handle}`);
                } else {
                    navigate(`/${channel}`);
                }

            })
            .catch((reason: any) => {
                localStorage.setItem('postcode', '');
                if ( reason.code === 803 ) {
                    setInlineErrorMessage(reason.message);
                }
            })
            .finally(() => {
                setLoading(false);
                setRedirecting(false)
            });
    };

    useEffect(() => {
        if ( postcode.length === 0 ) { return; }
        let valid_check = isValidUKPostcode(postcode);
        if (valid_check) {
            setLoadingOptions(true);
            findLoquateData(postcode).then((r: AxiosResponse) => {
                if (r.data) {
                    setAddressOptions(r.data.Items);
                    setLoadingOptions(false);
                }
            }).catch((error) => {
                trackAlert('loquate-error', operatorContext.operator?.handle ?? '', error.message)
                    .then(r => console.log('Alert sent'));
                setLoadingOptions(false);
                handleClear();
            });
        }
        setIsValidPostcode(valid_check)

    }, [postcode]);

    const handleLoquateClick = (address: any) => {
        if ( address['Type'] === 'Address' ) {
            setSelectedAddress(address.Text);
            setLoading(false);
            setRedirecting(true);
            retrieveLoquateData(address['Id']).then((r: AxiosResponse) => {
                if (r.data) {
                    const data = r.data.Items[0];
                    if (data) {
                        const { newline1, newline2 } = formatAddress({
                            Line1: data.Line1,
                            Line2: data.Line2,
                            Line3: data.Line3,
                            Line4: data.Line4,
                            Line5: data.Line5,
                            SubBuilding: data.SubBuilding,
                            Company: data.Company
                        });

                        const formattedAddress = {
                            address1: newline1, // Trim any extra spaces
                            address2: newline2, // Trim any extra spaces
                            city: data.City,
                            postcode: data.PostalCode
                        }

                        localStorage.setItem('address', JSON.stringify(formattedAddress));
                        handleDeliveryStart(data.PostalCode);
                    }
                }
            });


        } else if ( address['Type'] === 'Container' ) {
            findLoquateData(postcode, address['Id']).then((r: AxiosResponse) => {
                if (r.data) {
                    setAddressOptions(r.data.Items);
                }
            })
        }
        return null;
    };


    function formatAddress(address: { Line1?: "" | undefined; Line2?: "" | undefined; Line3?: "" | undefined; Line4?: "" | undefined; Line5?: "" | undefined; SubBuilding?: "" | undefined; Company?: "" | undefined; }) {
        // Extract the address components
        const {
            Line1 = '',
            Line2 = '',
            Line3 = '',
            Line4 = '',
            Line5 = '',
            SubBuilding = '',
            Company = ''
        } = address;

        // Collect all non-empty lines
        let lines = [Line1, Line2, Line3, Line4, Line5].filter(line => line !== '');

        // Initialize newline1 and newline2
        let newline1 = '';
        let newline2 = '';

        // Collect unique company and sub-building information
        const additionalInfo = [Company, SubBuilding].filter(part => part !== '');
        //.filter(part => part !== Line1);

        // remove any lines duplicating sub-building or company
        lines = lines.filter(part => !additionalInfo.includes(part));

        // Add the first line to newline1
        if (lines.length > 0) {
            newline1 += lines.shift();
        }

        if (lines.length > 2) {
            newline1 += ', ' + lines.shift();
        }

        // Add Company and SubBuilding to newline1 within parentheses if they are present and not equal to Line1
        if (additionalInfo.length > 0) {
            newline1 += ` (${additionalInfo.join(', ')})`;
        }

        // Add the second line to newline2 if it exists
        if (lines.length > 0) {
            newline2 += lines.shift();
        }

        // Add the remaining lines, joined by commas
        if (lines.length > 0) {
            const remainingLines = lines.join(', ');
            if (newline1 && !newline2) {
                newline2 = remainingLines;
            } else if (newline2) {
                newline2 += ', ' + remainingLines;
            } else {
                newline1 += (newline1 ? ', ' : '') + remainingLines;
            }
        }

        return { newline1, newline2 };
    }
    const isValidUKPostcode = (postcode:string) => {
        let stripped_postcode = postcode.replace(/\s*/g,"")
        const regex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
        return regex.test(stripped_postcode);
    }

    const handleClear = () => {
        localStorage.setItem('postcode', '');
        localStorage.setItem('address', '{}');
        setPostcode('');
        setSelectedAddress('');
        setIsValidPostcode(false);
        setInlineErrorMessage('');
    }

    const handlePostcodeFocus = () => {
        if (selectedAddress !== '') {
            handleClear();
        }
    }

    return (
        <StyledForm>
            <InputWrapper open={isValidPostcode && !selectedAddress}>
                <Form.Label>
                    Where should we deliver?
                </Form.Label>
                <div className={'postcode-input-wrapper'}>
                    <Form.Control
                        size="lg"
                        type="text"
                        onFocus={handlePostcodeFocus}
                        className={"postcode-input" + (selectedAddress !== '' ? ' postcode-input--active' : '')}
                        placeholder={t`Enter your postcode`}
                        value={selectedAddress === '' ? postcode : selectedAddress}
                        onChange={(e) => setPostcode(e.target.value)}
                        autoFocus
                    />
                    <i className={'postcode-input__search feather feather-search feather-24'}/>
                    {postcode.length > 0 ? (
                        loadingOptions ? (
                            <div className={'postcode-input__loading-wrapper'}>
                                <div className={'postcode-input__loading '}><PageSpinnerTwo show={true} color={'#8E8E93'}/></div>
                            </div>
                        ) : (
                            <i className={'postcode-input__clear feather feather-x feather-24'} onClick={handleClear} />
                        )
                    ) : null}
                    {!selectedAddress && isValidPostcode && !loadingOptions ? (
                        <StyledContainer>
                            { addressOptions.map((address) => { return (
                                <AddressOption
                                    key={address['Id']}
                                    onClick={() => handleLoquateClick(address)}
                                >
                                    <span>{ address['Text'] } </span>
                                    { String(address['Description']).includes(' - ') ? ' - ' : '' }
                                    <span className={'address_num'}>{ String(address['Description']).replace(/^[-\s]+/, '') }</span>
                                </AddressOption>
                            )})
                            }

                        </StyledContainer>
                    ) : null }
                </div>

                { inlineErrorMessage !== '' && (
                    <ErrorContainer>
                        {/*<i className="feather feather-info feather-20 color-filter-invalid" />*/}
                        { inlineErrorMessage }
                    </ErrorContainer>
                ) }


                {/*<MainButton className={`${!postcode || postcode.length < 3 || loading ? 'disabled' : ''}`} onClick={handleDeliveryStart} disabled={!postcode || postcode.length < 3 || loading} block>
                    {t`Start my order`}
                </MainButton>*/}

            </InputWrapper>

            <CTA
                className={"delivery-cta"}
                block disabled={selectedAddress === '' || loading || inlineErrorMessage !== ''}>
                <span>{t`Start my order`}</span>
                <StyledSpinnerOverlay show={redirecting}>
                    <PageSpinnerThree show={true} color={'#FFFFFF'}/>
                </StyledSpinnerOverlay>
            </CTA>

        </StyledForm>
    );
};

const StyledSpinnerOverlay = styled.div<{show: boolean}>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #EBEBEB;
    transition: opacity 0.1s ease-in-out;
    opacity: ${(props) => props.show ? '1' : '0'};
    border-radius: 6px;
`;

const ErrorContainer = styled.div`
    color: var(--invalid);
    padding-top: 8px;
    display: flex;
    align-items: flex-start;
    img { margin-right: 12px; }
    & i { margin-right: 8px; }
`;

const StyledForm = styled(Form.Group)`
    padding-bottom: 50px;
    padding: 0 48px;
    min-height: 400px;
    @media screen and (max-width: 767px) {
        padding: 0 20px;
    }
    .delivery-cta {
      height: 48px;
      padding-top: 18px;
      padding-bottom: 18px;
    }
`;

const AddressOption = styled.div`
    padding: 14px 16px;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    cursor: pointer;
    .address_num {
        color: var(--brand);
    }
    &:hover {
        background-color: var(--brand-10);
    }
`;

const StyledContainer = styled.div`
    border: 1px var(--border-grey) solid;
    width: 100%;
    background: var(--white);
    max-height: 242px;
    overflow-y: auto;
    position: absolute;
    top: 48px;
    right: 0;
    left: 0;
    z-index: 999;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;


const InputWrapper = styled.div<{open: boolean}>`
    position: relative;
    margin-bottom: 20px;
    
    .form-label {
        // font-size: var(--core_text_ui_lg);
        font-size: 16px;
        @media screen and (min-width: 576px) {
            font-size: var(--core_text_ui_lg_desktop);
        }
        line-height: 24px;
        color: var(--text-black);
        margin-bottom: 8px;
    }
    
    .postcode-input-wrapper {
        position: relative;
    }
    .postcode-input--active {
        // background-color: var(--brand-10);
        border-color: var(--brand);
    }
    .postcode-input__search, .postcode-input__clear {
        position: absolute;
        top: 8px;
        padding: 4px;
        border-radius: 16px;
       
    }
    .postcode-input__search {
        color: var(--text-black);
        left: 12px;
    }
    .postcode-input__clear {
        color: var(--text-grey);
        right: 12px;
        cursor: pointer;
        &:hover {
           background-color: var(--bg-grey);
        }
    }
    
    .postcode-input__loading {
        position: relative;
        height: 24px; width: 24px;
    }
    
    .postcode-input__loading-wrapper {
        position: absolute;
        top: 8px;
        right: 12px;
        padding: 4px;
    }
    
    

    .site-filter-input {
        height: 50px;
        &::placeholder { color:var(--placeholder); }
    }


    i.search-icon {
        position: absolute;
        top: calc(24px - 12px); /* 24 is half of height 48) */
        left: 12px;
    }
    i.find-icon {
        position: absolute;
        top: calc(24px - 12px); /* 24 is half of height 48) */
        right: 12px;
        &.with-input { display: none; }
        &:hover { cursor: pointer; }
    }

    .form-control {
        font-size: var(--core_text_ui_lg);
        @media screen and (min-width: 576px) {
            font-size: var(--core_text_ui_lg_desktop);
        }
        line-height: 24px;
        padding: 16px 48px 16px 48px;
        margin-bottom: 0px;
        ${(props) => props.open ? 'border-bottom-left-radius: 0; border-bottom-right-radius: 0;' : null}

        @media screen and (max-width: 768px) {
            background-position: 17px 12px, right 19px center;
        }
    }

    .location_icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
        cursor: pointer;
    }

    .spinner-grow {
        height: 18px;
        width: 18px;
    }
`;

export default DeliveryTab;
