import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { t } from 'ttag';
import config from '../../../../config/channels';
import useBasketContext from '../../../../contexts/basket/useBasketContext';
import { TChannel } from '../../../../contexts/operator/operator-context';
import useOperatorContext from '../../../../contexts/operator/useOperatorContext';
import useSiteContext from '../../../../contexts/site/useSiteContext';
import Alert from '../../../general/Alert';
import { LinkButton } from '../../../general/Button';
import SiteModal from './site/SiteModal';
import CartButton from './order/CartButton';
import LeavingModal from "../LeavingModal";
import {iLog} from "../../../../index";
import mixpanel from "mixpanel-browser";


interface MenuNavProps {
    handleClick: () => void;
    channel: TChannel;
    singleSiteAndChannel: boolean;
    cartOpen: () => void;
}

const MenuNav = ({ handleClick, channel, singleSiteAndChannel = false, cartOpen }: MenuNavProps) => {

    const navigate = useNavigate();

    const siteContext = useSiteContext();
    const operatorContext = useOperatorContext();
    const basketContext = useBasketContext();
    const themeContext = useContext(ThemeContext);

    const isDineIn = siteContext.channel === config.dineIn.name;

    const [siteShow, setSiteShow] = useState(false);
    const [leavingShow, setLeavingShow] = useState(false);

    const handleLeavingClick = () => {
        iLog('Leaving so soon?', [basketContext.basketItems.length, basketContext.basketItems.length > 0])

        mixpanel.track('Change location clicked');

        if ( basketContext.basketItems.length > 0 ) {
            setLeavingShow(true);
        } else {
            window.scrollTo(0,0);
            navigate(`/${isDineIn ? '' : siteContext.channel}`);
        }
    };


    return (
        <StyledNav className="menu-nav-wrapper">
            <SiteModal show={siteShow} onHide={() => setSiteShow(false)} />
            <NavContent>
                <StyledNavBrand onClick={() => navigate('/')} className="logoClass" />
                <StyledRightPanel>
                    <div className={'overflow-location'}>
                        <StyledLocation
                            role="button"
                            style={{ fontSize: '14px' }}
                            onClick={handleLeavingClick}
                        >
                            <i className="feather feather-map-pin feather-20 color-filter-change-location" />

                            {siteContext.channel === 'collection' && (
                                <>
                                    <span>{siteContext.site?.name}</span>
                                </>
                            )}

                            { ['delivery', 'catering'].includes(siteContext.channel) && <>
                                <span>{ localStorage.getItem('postcode') ?? '/' }</span>
                            </>}

                            {siteContext.channel === 'dine_in' && <>
                                <span>{t`Table `} {basketContext.tableNumber}</span>
                            </>}
                        </StyledLocation>
                    </div>
                    <div>
                        <CartButton
                            CTAProps={{ cart: true }}
                            onClick={cartOpen}/>
                    </div>
                </StyledRightPanel>
                <LeavingModal
                    show={leavingShow}
                    onHide={() => { setLeavingShow(false); }}
                />
            </NavContent>

            <div className="d-md-none d-none">
                {operatorContext.operator?.allergen_info ? (
                    <Alert variant="info" icon={'info'}>
                        <p className="mb-1">{t`Please, read allergen information.`}</p>
                        <p className="mb-0">
                            {' '}
                            <LinkButton
                                color={themeContext.color_info}
                                onClick={handleClick}
                                tight
                                underline
                            >{t`Learn more`}</LinkButton>
                        </p>
                    </Alert>
                ) : null}
            </div>
        </StyledNav>
    );
};



const StyledLocation = styled.div`
    display: none;
    transition: background-color 0.2s ease-in-out;
    
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        height: 36px;
        border-radius: 18px;
        padding: 0 20px 0 16px;
        overflow: hidden;
       
        i { margin-right: 8px; font-weight: 500;}

        span {
            display: inline-block;
            color: var(--ui_buttons_change_location_color);
            font-family: var(--text_ui_font);
            text-transform: var(--text_ui_case);
            font-weight: 500;
            font-size: var(--ui_buttons_change_location_size);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-bottom: 1px solid var(--ui_buttons_change_location_color);
            transition: border-color 0.2s ease-in-out;
        }

    }

`;

const StyledRightPanel = styled.div`
    display: flex;
    gap: 24px;
    min-width: 0;
    align-items: center;
    flex-shrink: 1;
    .overflow-location {
        overflow: hidden;
    }
`;

const StyledNav = styled.div`
  
    position: relative;
    z-index: 100;
    h5 {
        span {
            display: inline-block;
            vertical-align: middle;
        }
        span:first-of-type {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: calc(100% - 25px);
        }
    }
    hr {
        padding: 0;
        margin: 12px 0;
    }
    .change-collection-link {
        background: transparent !important;
        font-weight: 400;
    }
    
    @media screen and (min-width: 768px) {
        padding: 12px 0;
    }

`;

const NavContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
`;

const StyledNavBrand = styled.div`
    background-image: var(--logo);
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    height: var(--logo_height);
    width: 290px;
    max-width: 85vw;
    display: block;
    cursor: pointer;
    flex-shrink: 0;
`;


export default MenuNav;